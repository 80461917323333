<script lang="ts" setup>
import MenuIcon from '~/assets/icons/ui/Menu.svg'
import LogoutIcon from '~/assets/icons/ui/Logout.svg'

defineEmits(['toggle'])

const { formattedFullname } = useAuthorization()
</script>

<template>
  <div class="flex items-center justify-between gap-2 m-2 p-2 rounded border bg-white border-gray-300 z-50">
    <div class="flex items-center gap-2">
      <n-button quaternary type="primary" class="!p-1" @click="$emit('toggle')">
        <div class="h-6 w-6">
          <MenuIcon />
        </div>
      </n-button>
      <p>
        {{ formattedFullname }}
      </p>
    </div>
    <NuxtLink to="/auth/logout">
      <n-button quaternary type="primary" class="!p-1">
        <div class="h-6 w-6">
          <LogoutIcon />
        </div>
      </n-button>
    </NuxtLink>
  </div>
</template>
